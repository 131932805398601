import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_proto_heart_hemodynamic_information = _resolveComponent("proto-heart-hemodynamic-information")!
  const _component_proto_heart_echo_results = _resolveComponent("proto-heart-echo-results")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_ctx.isProtoHeartSpecificDetailsEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_card_section, { "section-id": "proto_heart_specific_details" }, {
          header: _withCtx(() => [
            _createTextVNode(" Heart Specific Details ")
          ]),
          body: _withCtx(() => [
            _createElementVNode("form", null, [
              _createVNode(_component_proto_heart_hemodynamic_information),
              _createVNode(_component_proto_heart_echo_results)
            ])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}