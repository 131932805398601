import { APIRecipientDialysisInterface } from '@/APIModels/recipients/types';
import { APINewResonse, APIPermittedActions, APIRules, APIShowResponse, RulesQuery, SaveResult } from '@/types';
import axios from 'axios';
import { APIRoute, EP } from '@/api-endpoints';
import { UIError } from '@/UIModels/error';
import { UISuccess } from '@/UIModels/success';
import { APIShowRecipientDialysisData, APISaveRecipientDialysisResponse } from '@/types';
import { useCurrentPageStore } from '@/stores/currentPage';
import vuexStore from '@/store'; // for gradual conversion, see fullUserDetails
import { UIRecipient } from '../recipient';
import { sanitizePhoneNumber } from '@/utils';

export class UIRecipientDialysis {
  public apiSource: APIRecipientDialysisInterface|null = null;
  public id: string|null = null;

  public access_laterality_code: string|null = null;
  public access_method_code: string|null = null;
  public dialysis_type_code: string|null = null;
  public end_date: string|null = null;
  public facility_name: string|null = null;
  public facility_phone_number: string|null = null;
  public location_type_code: string|null = null;
  public reason_code: string|null = null;
  public start_date: string|null = null;

  public loaded = false;

  public permittedActions: APIPermittedActions[] = [];

  get canEdit(): boolean {
    return this.permittedActions.includes(APIPermittedActions.Create) || this.permittedActions.includes(APIPermittedActions.Update);
  }

  // Define new UI view model structure
  public constructor(apiDialysis: APIRecipientDialysisInterface|null = null) {
    if (apiDialysis) this.updateFromAPIResponse(apiDialysis);
  }

  // Load resource data and permitted actions
  public async load(opts: { recipientId: string, id: string, query: RulesQuery }): Promise<void> {
    if (!opts.recipientId) return;
    if (opts.id) {
      await this.loadShow(opts);
    } else {
      await this.loadNew(opts);
    }
  }

  // Load list for specified recipient
  public static async loadFor(uiRecipient: UIRecipient): Promise<UIRecipientDialysis[]> {
    const recipientId = uiRecipient.clientId || '';
    let result: UIRecipientDialysis[] = [];
    const url = APIRoute(EP.recipients.dialysis.index, [[':recipient_id', recipientId]]);
    await axios.get(url).then((response: any) => {
      const apiDialysis: APIRecipientDialysisInterface[] = response?.data?.dialysis_procedures || [];
      result = apiDialysis.map((item: APIRecipientDialysisInterface): UIRecipientDialysis => {
        return new UIRecipientDialysis(item);
      });
    }).catch((error: any) => {
      console.warn(error);
      throw new UIError('patient_dialysis');
    });
    return result;
  }

  private async loadNew(opts: { recipientId: string, id: string, query: RulesQuery }): Promise<void> {
    const url = APIRoute(EP.recipients.dialysis.index, [[':recipient_id', (opts.recipientId)], [':id', (opts.id)]]);
    try {
      const response: APINewResonse = await axios.get(url, { params: opts.query });
      this.permittedActions = response.data.permitted_actions;
      this.setRules(response.data.rules);
      this.loaded = true;
    } catch (error: unknown) {
      this.loaded = true;
      console.warn(error);
    }
  }

  private async loadShow(opts: { recipientId: string, id: string }): Promise<void> {
    const url = APIRoute(EP.recipients.dialysis.show, [[':recipient_id', (opts.recipientId)], [':id', (opts.id)]]);
    try {
      const response: APIShowResponse<APIShowRecipientDialysisData> = await axios.get(url);
      const apiDialysis: APIRecipientDialysisInterface = response.data.dialysis_procedure;
      this.permittedActions = response.data.permitted_actions;
      this.setRules(response.data.rules);
      this.updateFromAPIResponse(apiDialysis);
      this.loaded = true;
    } catch (error: unknown) {
      this.loaded = true;
      console.warn(error);
    }
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiDialysis: APIRecipientDialysisInterface) {
    this.apiSource = apiDialysis;
    this.id = apiDialysis._id?.$oid || null,
    this.access_laterality_code = apiDialysis.access_laterality_code || null;
    this.access_method_code = apiDialysis.access_method_code || null;
    this.dialysis_type_code = apiDialysis.dialysis_type_code || null;
    this.end_date = apiDialysis.end_date || null;
    this.facility_name = apiDialysis.facility_name || null;
    this.facility_phone_number = apiDialysis.facility_phone_number || null;
    this.location_type_code = apiDialysis.location_type_code || null;
    this.reason_code = apiDialysis.reason_code || null;
    this.start_date = apiDialysis.start_date || null;
  }

  private setRules(rules: APIRules): void {
    vuexStore.commit('validations/resetPrefix', 'patient_dialysis');
    vuexStore.commit('validations/set', { rules: { ['patient_dialysis']: rules } });
  }

  // Is this an unsaved New Dialysis?
  get isNew(): boolean {
    return !this.id;
  }

  // Save UI Dialysis edit state to the backend
  public save(opts: { selected: UIRecipientDialysis|null, recipient: UIRecipient }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const recipientId = opts.recipient.clientId;
      if (!recipientId) reject((new UIError('patient_dialysis')));
      const id = opts.selected?.id;

      let method: any;
      let ep: string;
      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.dialysis.update, [[':recipient_id', recipientId as string], [':id', id]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.dialysis.create, [[':recipient_id', recipientId as string]]);
      }
      const payload = {
        procedure_dialysis: this.extractPatch()
      };
      method(ep, payload).then((response: APISaveRecipientDialysisResponse) => {
        if (response.data.errors) {
          reject((new UIError('patient_dialysis', response)).errorResult);
        } else {
          // Success! We may need to update the current page
          opts.recipient.load({ reload: true }).then(() => {
            resolve((new UISuccess(response)).getSaveResult());
          });
        }
      }).catch((errorResponse: any) => {
        reject((new UIError('patient_dialysis', errorResponse)).errorResult);
      });
    });
  }

  // Generate request payload parameters to provide to API as part of Create or Update activity
  private extractPatch(): APIRecipientDialysisInterface {
    const result = {
      access_laterality_code: this.access_laterality_code || null,
      access_method_code: this.access_method_code || null,
      dialysis_type_code: this.dialysis_type_code || null,
      end_date: this.end_date || null,
      facility_name: this.facility_name || null,
      facility_phone_number: sanitizePhoneNumber(this.facility_phone_number) || null,
      location_type_code: this.location_type_code || null,
      reason_code: this.reason_code || null,
      start_date: this.start_date || null
    };

    return result;
  }
}
