import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_proto_medical_history = _resolveComponent("proto-medical-history")!
  const _component_proto_surgical_history = _resolveComponent("proto-surgical-history")!
  const _component_proto_social_history = _resolveComponent("proto-social-history")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_ctx.isProtoPatientHistoryEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_card_section, { "section-id": "proto_patient_history" }, {
          header: _withCtx(() => [
            _createTextVNode(" Patient History ")
          ]),
          body: _withCtx(() => [
            _createElementVNode("form", null, [
              _createVNode(_component_proto_medical_history),
              _createVNode(_component_proto_surgical_history),
              _createVNode(_component_proto_social_history)
            ])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}