<template>
  <sub-section
    :title="$t('egfr_subtitle')"
    sub-section-id="sub-section-egfr"
    v-if="isRecipientEgfrEnabled"
  >
    <template v-slot:contents>
      <!-- Filter Bar + Create Button -->
      <table-toolbar
        :createButton="canSave"
        :createText="$t('egfr_create_record')"
        @table-create-row="handleTableCreateRow"
      />

      <!-- List of Items, or History List -->
      <table-list
        ref="recipientEgfrTable"
        table-id="recipient-egfr-table"
        :table-config="tableConfig"
        @table-row-click="handleTableRowClick"
        :highlightSelection="true"
        :firstColumnTabbable="true"
      />

      <egfr-form
        form-id="recipientEgfrForm"
        ref="recipientEgfrForm"
        :selection="selection"
        :canSave="canSave"
        @success="handleSuccess"
      />

    </template>
  </sub-section>
</template>

<script lang="ts">
import { Getter } from 'vuex-facing-decorator';
import { Component, mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import SubSection from '@/components/shared/SubSection.vue';
import { UIRecipientEgfr } from '@/UIModels/recipients/recipientEgfr';
import { UIConfiguration } from '@/UIModels/configuration';
import { UIRecipient } from '@/UIModels/recipient';
import { useCurrentPageStore } from '@/stores/currentPage';
import TableToolbar from '@/components/shared/TableToolbar.vue';
import TableList from '@/components/shared/TableList.vue';
import { UIListFormSelection } from '@/UIModels/listFormSelection';
import { i18nMessages } from "@/i18n";
import { SaveResult } from '@/types';
import { TableConfig } from '@/types';
import EgfrForm from '@/components/recipients/egfrDialysis/EgfrForm.vue';

interface TableRow {
  id: string|null;
  date: string|null;
  egfr: string|null;
  comments: string|null;
}

const PAGE_SIZES = [5, 10, 25];
const DEFAULT_PAGE_SIZE = PAGE_SIZES[0]; // 5

@Component({
  components: {
    SubSection,
    TableToolbar,
    TableList,
    EgfrForm
  },
  ...i18nMessages([
    require('@/components/recipients/_locales/common.json'),
    require('@/components/recipients/_locales/RecipientEgfrDialysis.json'),
  ]),
  emits: [
    'loaded',
  ],
})
export default class EgfrSubSection extends mixins(DateUtilsMixin) {
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;

  // Check if Recipient Egfr is enabled
  get isRecipientEgfrEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.recipientEgfr.enabled;
  }

  // Selection instance
  private selection = new UIListFormSelection();

  // Editable view model for the form
  private editState = new UIRecipientEgfr();

  get recipientEgfrTable(): TableList {
    return this.$refs.recipientEgfrTable as TableList;
  }

  // Which Configuration view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentConfiguration(): UIConfiguration {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.configuration as UIConfiguration;
  }

  // Which Recipient view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentRecipient(): UIRecipient {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.currentRecipient as UIRecipient;
  }

  // Clear selection
  private handleSuccess(_success: SaveResult): void {
    this.recipientEgfrTable.resetSelection();
    this.selection = new UIListFormSelection();
  }

  get canSave(): boolean {
    return this.isGroupWriteable("recipient_private");
  }

  get getRecords(): UIRecipientEgfr[] {
    return this.currentRecipient.recipientEgfrs || [];
  }

  // Prepare recipient attachments for presentation in the historical tables.
  get tableRows(): TableRow[] {
    const rows: TableRow[] = this.getRecords.map((item: UIRecipientEgfr) => {
      return {
        id: item.id,
        date: this.parseDisplayDateUi(item.date) || '-',
        egfr: item.egfr,
        comments: item.comments || '-',
      };
    });
    return rows;
  }

  get tableConfig(): TableConfig {
    const tableConfig = [
      { label: `${this.$t('egfr_date')}`,
        field: 'date',
        sortable: false,
      },
      { label: `${this.$t('egfr_egfr')}`,
        field: 'egfr',
        sortable: false,
      },
      { label: `${this.$t('egfr_comments')}`,
        field: 'comments',
        sortable: false
      },
    ];

    return {
      data: this.tableRows,
      columns: tableConfig,
      empty: this.canSave ? this.$t('egfr_new_result_text').toString() : this.$t('egfr_no_results_text').toString(),
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPageDropdown: PAGE_SIZES,
        defaultPageSize: DEFAULT_PAGE_SIZE,
        position: 'bottom'
      }
    };
  }

  private handleTableCreateRow(): void {
    this.recipientEgfrTable.resetSelection();
    this.selection = new UIListFormSelection();
  }

  /**
   * Event handle run when clicking on the edit button on a row in the post transplant follow up table.
   */
  handleTableRowClick(event: { row: TableRow }): void {
    const listItem = this.getRecords.find((item: UIRecipientEgfr) => { return item.id === event.row.id; });
    if (!listItem) return;

    this.selection = new UIListFormSelection(listItem.id);
  }
}
</script>
