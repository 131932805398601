<template>
  <card-section 
    section-id="notes-section" 
    class="spaced-list-items"
    ref="notesSection"
    :lookups-to-load="lookupsToLoad"
    >
    <template v-slot:header>
      {{ $t("patient_notes") }}
    </template>
    <template v-slot:body>
      <!-- Sub-section container -->
      <sub-section sub-section-id="notes-sub-section" :title="$t('note_history')">
        <template v-slot:guiding_text>
          {{ $t('title_patient_note_guiding_text') }}
        </template>
        <template v-slot:contents>
          <!-- Filter Bar + Create Button -->
          <table-toolbar
            :createButton="canSave && !newJourney"
            :createText="$t('create_note')"
            @table-create-row="handleTableCreateRow"
          >
            <template v-slot:button-bar>
              <div class="filter-section-action-row">
                <div class="filter-section-wrapper">
                  <filter-component
                    :title="$t('filter_notes')"
                    fieldID="notesFilter" 
                    :showFilter="true"
                    :showNotesFilter="true"
                    :showArchived="true"
                    :tagOptions="getNoteTags" 
                    :authorOptions="getUserIds" 
                    @setFilters="setFilters" 
                    />
                </div>
              </div>

            </template>

          </table-toolbar>

          <!-- List of Items, or History List -->
          <table-list
            table-id="notes-sub-section-table"
            ref="notesTableList"
            tabbableColumn="date"
            :table-config="tableConfig"
            @table-row-click="handleTableRowClick"
            :highlightSelection="true"
            @onPageNumber="onPageNumber($event); loadData()"
            @onPageSize="onPageSize($event); loadData()"
            mode="remote"
            :rowStyleClass="rowStyleClass"
            groupMode="subheader"
            groupBy="id"
            :isLoading="isLoading"
            :total-records="totalRecords"
          />

          <!-- Form layout -->
          <validation-observer ref="validations">
            <form-layout
              :disabled="!canSave || newJourney || isArchived"
              form-id="notes-sub-section-form"
            >
              <template v-slot:title>
                <!-- Mode indicator / subsection form title -->
                <legend>
                  <h5 v-if="isEditing" class="legend-title">
                    {{$t('selected_patient_note')}}
                  </h5>
                  <h5 v-else class="legend-title">
                    {{$t('new_patient_note')}}
                  </h5>
                </legend>
                <template v-if="!newJourney">
                  <small v-if="isEditing">{{ $t('selected_patient_note_guiding_text') }}</small>
                  <small v-else>{{ $t('new_patient_note_guiding_text') }}</small>
                </template>
              </template>

              <template v-slot:action>
                <!-- Action Toolbar -->
                <action-toolbar
                  ref="actionToolbar"
                  @archive="performArchive"
                  :archiveGuidingText="$t('archive.guiding_text')"
                  @restore="performRestore"
                  :restoreGuidingText="$t('restore.guiding_text')"
                  :permittedActions="permittedActions"
                  :isArchiving="isArchiving"
                  :isRestoring="isRestoring"
                  :rationaleRequired="true"
                />
              </template>

              <template v-slot:contents>
                <!-- Data Entry Form (Add, View, and Edit modes) -->
                <div class="row">
                  <div class="standard-form-group">
                    <text-input
                      input-id="note_subject"
                      ruleKey="clinical_notes.note_subject"
                      :name='$t("note_subject")'
                      v-model="editState.note_subject"
                    />
                  </div>
                  <div class="standard-form-group">
                    <AutoCompleteInput
                      ruleKey="clinical_notes.note_tags"
                      :name='$t("note_tags")'
                      :options="recipientJourneyNoteTags"
                      v-model="editState.note_tags"
                      :disabled="!canSave || newJourney || isArchived"
                      inputId="note_tags"
                      :multiple="true"
                      :showDropdown="true"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="standard-full-width-group">
                    <text-area-input
                      input-id="note_text"
                      ruleKey="clinical_notes.note_text"
                      :name='$t("note_text")'
                      v-model="editState.note_text"
                      rows="4"
                    />
                  </div>
                </div>

                <!-- edit history -->
                <template v-if="isEditing">
                  <div class="row-break d-none d-xl-block"></div>
                  <div class="row" v-if="canSave && !newJourney">
                    <div class="standard-full-width-group mb-4">
                      <text-input
                        input-id="change_rationale"
                        :name='$t("change_rationale")'
                        ruleKey="clinical_notes.change_rationale"
                        v-model="editState.change_rationale"
                        >
                      </text-input>
                    </div>
                  </div>
                  <div class="row-break d-none d-xl-block"></div>
                  <label for="noteHistoryTable">{{ $t("previous_edits_rationale") }}</label>

                  <!-- List of Items, or History List -->
                  <table-list
                    table-id="noteHistoryTable"
                    ref="noteHistoryTable"
                    :table-config="tableEditHistoryConfig"
                    :rowStyleClass="rowHistoryStyleClass"
                  />
                </template>
              </template>
              <template v-slot:save>
                <save-toolbar
                  :show="canSave && !newJourney"
                  ref="notesSaveToolbar"
                  :label="$t('save_patient_note')"
                  :cancelButton="true"
                  @save="savePatch()"
                  @cancel="cancelPatch()"
                />
              </template>
            </form-layout>
          </validation-observer>
        </template>
      </sub-section>

    </template>
  </card-section>
</template>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-facing-decorator';
import { Organ } from '@/store/lookups/types';
import { Recipient } from '@/store/recipients/types';
import DateInput from '@/components/shared/DateInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import TableToolbar from '@/components/shared/TableToolbar.vue';
import TableList from '@/components/shared/TableList.vue';
import FormLayout from '@/components/shared/FormLayout.vue';
import ActionToolbar from '@/components/shared/ActionToolbar.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { Component, Prop } from 'vue-facing-decorator';
import { IdLookup } from '@/store/validations/types';
import SelectInput from '@/components/shared/SelectInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { SaveableSection, SaveResult } from '@/types';
import { RecipientJourney, ClinicalNoteView, ClinicalNoteModel, UINotesFilterOptionsInterface, UIFilterAssignments } from '@/store/recipientJourney/types';
import { optionsFor, parseFormErrors } from "@/utils";
import { EP } from '@/api-endpoints';
import { GenericCodeValue } from '@/store/types';
import FilterComponent from "@/components/shared/filter/FilterComponent.vue";
import { i18nMessages } from "@/i18n";
import { UIListFormSelection } from '@/UIModels/listFormSelection';
import { RemotePaginationMixin } from "@/mixins/remote-pagination-mixin";
import AutoCompleteInput from '@/components/shared/AutoCompleteInput.vue';

export interface ClinicalNotePayload {
  clinical_note: ClinicalNoteModel
}
const DEFAULT_PAGE_SIZE = 10;

@Component({
  components: {
    TextInput,
    DateInput,
    CardSection,
    SubSection,
    TableToolbar,
    TableList,
    FormLayout,
    ActionToolbar,
    SaveToolbar,
    SelectInput,
    TextAreaInput,
    FilterComponent,
    AutoCompleteInput
  },
  ...i18nMessages([
    require('@/components/organs/shared/_locales/ClinicalNotesSection.json'),
    require('./_locales/common.json'),
  ]),
})
export default class ClinicalNotesSection extends mixins(DateUtilsMixin, RemotePaginationMixin) implements SaveableSection {
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.lookups.recipient_journey_note_tags) recipientJourneyNoteTags!: any[];

  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState', }) journeyId!: string|undefined;

  @Prop({ default: false }) newJourney!: boolean
  @Prop({ default: false }) canSave!: boolean;

  // Selection instance
  private selection = new UIListFormSelection();

  private isLoading = true;
  private lookupsToLoad = ["recipient_journey_note_tags"];
  private isEditing = false;
  private editState: ClinicalNoteView = {};

  private records: ClinicalNoteView[] = [];
  private totalRecords = 0;
  private permittedActions: string[] = [];

  private isArchiving = false;
  private isRestoring = false;

  // Figure out if we need any extra style class based on the row data
  private rowStyleClass(row: ClinicalNoteView): string|null {
    if (!row) return null;
    if (row.archived_at) return 'highlight-archived';
    return null;
  }

  // Figure out if we need any extra style class based on the row data
  private rowHistoryStyleClass(row: ClinicalNoteView): string {
    return 'table-bordered';
  }

  private filterOptions: UINotesFilterOptionsInterface = {
    date_from: null,
    date_to: null,
    note_tags: [],
    user_id: [],
    keywords: null,
  };

  private selectedFilters: UIFilterAssignments = {
    date_from: null,
    date_to: null,
    note_tags: [],
    user_id: [],
    keywords: null,
    include_archived: false
  };

  private setFilters(filterObject: UIFilterAssignments) {
    this.selectedFilters = filterObject;
    this.loadData();
  }

  get isArchived(): boolean {
    if (!this.isEditing) return false; // if new
    return this.editState && this.editState.archived_at !== null; 
  }

  get getNoteTags(): GenericCodeValue[] {
    const tags = this.filterOptions.note_tags.map((code: string) => {
      const found = this.recipientJourneyNoteTags.find((tag: GenericCodeValue) => { 
        return tag.code == code; 
      });
      if (found) return found;
    });
    return tags;
  }

  get getUserIds(): GenericCodeValue[] { return this.filterOptions.user_id ? optionsFor(this.filterOptions.user_id) : []; }

  mounted() {
    this.perPage = DEFAULT_PAGE_SIZE;
    this.loadFilterOptions();
    this.$store.dispatch('validations/loadValidationsWithActions', {
      route: EP.recipients.journeys.clinical_notes.new_validations, 
      payload: [[':recipient_id', this.recipientId], [':journey_id', this.journeyId]],
      prefix: 'clinical_notes'
    }).then((response: any) => {
      this.permittedActions = response.permitted_actions;
      this.loadData();
      this.editState = {};
      this.isEditing = false;
    }); 
  }

  loadFilterOptions() {
    this.$store.dispatch('journeyState/loadFilterOptions', {
      recipientId: this.recipientId, 
      journeyId: this.journeyId,
    }).then((response: any) => {
      this.filterOptions = response.responseData;
    });
  }

  public loadData(search='', sort='') {
    // build pagination params
    const params: any = {
      page_number: this.pageNumber,
      page_size: this.perPage,
    };

    // set filter params based on filter component
    if (this.selectedFilters.date_from) { params['date_from'] = this.selectedFilters.date_from; }
    if (this.selectedFilters.date_to) { params['date_to'] = this.selectedFilters.date_to; }
    if (this.selectedFilters.note_tags && this.selectedFilters.note_tags.length > 0) { params['note_tags'] = (this.selectedFilters.note_tags as any).split(', '); }
    if (this.selectedFilters.user_id && this.selectedFilters.user_id.length > 0) { params['user_id'] = this.selectedFilters.user_id; }
    if (this.selectedFilters.keywords) { params['keywords'] = this.selectedFilters.keywords; }
    if (this.selectedFilters.include_archived) { params['include_archived'] = this.selectedFilters.include_archived; }

    // load clinical notes
    this.isLoading = true;
    this.$store.dispatch(
      'journeyState/loadClinicalNotes', {
      recipientId: this.recipientId, 
      journeyId: this.journeyId,
      params: params
    }).then((success: SaveResult) => {
      this.records = success.responseData.records;
      this.totalRecords = success.responseData.count;
      this.isLoading = false;
    });
  }

  // Clear selection and handle scrolling for create button click event
  private handleTableCreateRow(): void {
    const notesTableList = this.$refs.notesTableList as TableList;
    notesTableList.resetSelection();
    this.selection = new UIListFormSelection();
    this.prepareNewEntry();
  }

  // Reset form edit state, field-level validation errors, and save toolbar
  private prepareNewEntry(): void {
    this.resetFormErrors();
    this.editState = {};
    this.$store.dispatch('validations/loadValidationsWithActions', {
      route: EP.recipients.journeys.clinical_notes.new_validations, 
      payload: [[':recipient_id', this.recipientId], [':journey_id', this.journeyId]],
      prefix: 'clinical_notes'
    }).then((response: any) => {
      this.permittedActions = response.permitted_actions;
      this.isEditing = false;
      this.resetSaveToolbar();
    }); 
  }

  // Select item from the list based on a row click event
  private handleTableRowClick(event: { row: ClinicalNoteView }): void {
    const listItem = this.records.find((listItem: ClinicalNoteView) => { return listItem.id == event.row.id; });
    if (!listItem) return;

    this.selection = new UIListFormSelection(listItem.id);
    this.prepareEditEntry();
  }

  // Initialize Edit form based on selection
  private prepareEditEntry(): void {
    const id = this.selection.id;
    // Reset form components
    this.resetFormErrors();
    this.resetSaveToolbar();
    // Request 'show' information
    this.$store.dispatch(
      'journeyState/loadClinicalNote', {
      recipientId: this.recipientId, 
      journeyId: this.journeyId,
      id: id,
    }).then((success: SaveResult) => {
      const responseData = success.responseData;
      this.editState = responseData.record;
      this.isEditing = true;
      // NOTE: check permitted_actions in 'show' instead of 'edit' to handle archived scenario
      this.permittedActions = responseData.permitted_actions;
      // If permitted to update, request 'edit' information
      if (this.permittedActions.includes('update')) {
        this.$store.dispatch('validations/loadValidationsWithActions', {
          route: EP.recipients.journeys.clinical_notes.edit_validations, 
          payload: [[':recipient_id', this.recipientId], [':journey_id', this.journeyId], [':id', id]],
          prefix: 'clinical_notes'
        }).catch((error: unknown) => {
          console.warn('Cannot load clinical note validations as part of preparing edit entry', error);
        });
      } else {
        // If not permitted to update, clear previously loaded rules
        this.$store.commit('validations/resetPrefix', 'clinical_notes');
      }
    }).catch((error: unknown) => {
      console.warn('Cannot load clinical note as part of preparing edit entry', error);
    });
  }

  get getColumns(): any {
    return [
      // needed for row grouping
      { 
        field: "id",
        hidden: true
       },
      {
        label: this.$t('date_note_added'),
        field: "date",
        sortable: false,
        class: "small-width-column"
      },
      {
        label: this.$t('note_subject'),
        field: "note_subject",
        sortable: false,
      },
      {
        label: this.$t('author'),
        field: "user",
        sortable: false,
        class: "small-width-column"
      },
      {
        label: this.$t('status'),
        field: "status",
        sortable: false,
        class: "small-width-column"
      },
      {
        label: this.$t('note_text'),
        field: "note_text",
        sortable: false,
        expanded: true
      }
    ];
  }

  get tableConfig(): TableConfig {
    return {
      data: this.records,
      columns: this.getColumns,
      sortOptions: {
        enabled: true,
        initialSortBy: [{ field: 'date', type: 'desc' }]
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPageDropdown: [10, 25, 50],
        dropdownAllowAll: false,
        position: 'bottom'
      }
    };
  }

  get getEditHistoryColumns(): any {
    return [
      {
        label: this.$t('date'),
        field: "date",
        sortable: false,
      },
      {
        label: this.$t('change_rationale'),
        field: "change_rationale",
        sortable: false,
      },
      {
        label: this.$t('edited_by'),
        field: "user",
        sortable: false,
      }
    ];
  }

  get tableEditHistoryConfig(): TableConfig {
    return {
      data: this.editState.version_history || [],
      columns: this.getEditHistoryColumns,
      sortOptions: {
        enabled: true,
        initialSortBy: [{ field: 'date', type: 'desc' }]
      },
      pagination: false,
      paginationOptions: {
        enabled: false,
        perPageDropdown: [10, 25, 50],
        dropdownAllowAll: false,
        position: 'bottom'
      }
    };
  }

  private performArchive(change_rationale: string): void {
    this.isArchiving = true;
    this.isRestoring = false;
    this.filingPatch(change_rationale, 'archive');
  }

  private performRestore(change_rationale: string): void {
    this.isArchiving = false;
    this.isRestoring = true;
    this.filingPatch(change_rationale, 'restore');
  }

  /**
   * Performs the archive / restore action.
   *
   * Given a payload and process, dispatches the relevant action, and registers the result.
   */
   public filingPatch(change_rationale: string, process: string): void {
    this.resetSaveToolbar(); // reset save toolbar

    // save patch
    const actionToolbar = this.$refs.actionToolbar as ActionToolbar;

    const payload: any = {
      clinical_note: {
        note_subject: this.editState.note_subject || null,
        note_tags: this.editState.note_tags || null,
        note_text: this.editState.note_text || null,
        change_rationale: change_rationale || null
      }
    };

    // Attempt to save
    this.$store.dispatch('journeyState/filingClinicalNote', { 
      payload: payload,
      process: process,
      recipientId: this.recipientId, 
      journeyId: this.journeyId,       
      id: this.editState.id || null
    }).then((success: SaveResult) => {
      // Register save result
      if (actionToolbar) {
        actionToolbar.resetToolbar();
      }
      // Re-load table
      this.loadData();
      // Re-load the form
      this.resetFormErrors();
      const responseData = success.responseData;
      this.editState = responseData.record;
      this.permittedActions = responseData.permitted_actions;
      this.isEditing = true;
    }).catch((error: SaveResult) => {
      this.handleErrors(error);
      if (actionToolbar) {
        actionToolbar.resetToolbar();
      }
    });

    this.isArchiving = false;
    this.isRestoring = false;
  }

  /**
   * Gets a patch object representing form edit state changes for this form
   *
   * Delegates the logic of building the patch to a local private method
   *
   * @returns {ClinicalNotePayload} patch object containing field changes
   */
  public extractPatch(): ClinicalNotePayload {
    const result: any = {
      clinical_note: {
        note_subject: this.editState.note_subject || null,
        note_tags: this.editState.note_tags || null,
        note_text: this.editState.note_text || null,
      }
    };
    if (this.isEditing) {
      result.clinical_note.change_rationale = this.editState.change_rationale || null;
    }
    return result;
  }

  /**
   * Saves the form edit state.
   *
   * Prepares an update payload for Referral Atributes, dispatches a save action, and registers the save result.
   */
   public savePatch(): void {
    // save patch
    const saveToolbar = this.$refs.notesSaveToolbar as SaveToolbar;
    if (saveToolbar) saveToolbar.startSaving();

    const payload = this.extractPatch();

    // Attempt to save
    this.$store.dispatch('journeyState/saveClinicalNote', { 
      payload: payload,
      recipientId: this.recipientId, 
      journeyId: this.journeyId,       
      id: this.editState.id || null
    }).then((success: SaveResult) => {
      // Register save result
      saveToolbar.stopSaving(success);
      this.handleSuccess();
    }).catch((error: SaveResult) => {
      this.handleErrors(error);
      saveToolbar.stopSaving(error);
    });
   }

  public cancelPatch(): void {
    // Re-initialize form
    if (this.selection.id) {
      this.prepareEditEntry();
    } else {
      this.prepareNewEntry();
    }
  }

  private handleSuccess() {
    this.loadData(); // Re-load table
    const notesTableList = this.$refs.notesTableList as TableList;
    notesTableList.resetSelection();
    this.loadFilterOptions(); // reload filter options
    this.resetFormErrors();

    // Re-initialize form
    this.editState = {};
    this.permittedActions = [];
    this.isEditing = false;
  }

  // Handle validations for this form component
  private handleErrors(errors: any) {
    // Derive errors for UI input fields based on API error results
    const formErrors: any = parseFormErrors(errors, this.idLookup());

    // inject api errors into vee-validate
    const validationObserver = this.$refs.validations as any;
    if (validationObserver) validationObserver.setErrors(formErrors);

    const saveToolbar = this.$refs.notesSaveToolbar as SaveToolbar;
    if (saveToolbar) saveToolbar.stopSaving(errors);
  }

  /**
   * Resets Form Errors
  */
  public resetFormErrors(): void {
    // Refer to the validations object
    const validations = this.$refs.validations as any;
    // Reset the form errors
    if (validations) validations.resetForm();
  }

  /**
   * Resets the save toolbar
  */
  public resetSaveToolbar(): void {
    // Refer to the save toolbar that handle the areas present on this form component
    const saveToolbar = this.$refs.notesSaveToolbar as SaveToolbar;
    // Reset the save toolbar
    if (saveToolbar) saveToolbar.reset();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    return {
      "note_subject"          : "note_subject",
      "note_tags"             : "note_tags",
      "note_text"             : "note_text",
      "change_rationale"      : "change_rationale",
    };
  }
}
</script>
