import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_toolbar = _resolveComponent("table-toolbar")!
  const _component_table_list = _resolveComponent("table-list")!
  const _component_egfr_form = _resolveComponent("egfr-form")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_ctx.isRecipientEgfrEnabled)
    ? (_openBlock(), _createBlock(_component_sub_section, {
        key: 0,
        title: _ctx.$t('egfr_subtitle'),
        "sub-section-id": "sub-section-egfr"
      }, {
        contents: _withCtx(() => [
          _createVNode(_component_table_toolbar, {
            createButton: _ctx.canSave,
            createText: _ctx.$t('egfr_create_record'),
            onTableCreateRow: _ctx.handleTableCreateRow
          }, null, 8, ["createButton", "createText", "onTableCreateRow"]),
          _createVNode(_component_table_list, {
            ref: "recipientEgfrTable",
            "table-id": "recipient-egfr-table",
            "table-config": _ctx.tableConfig,
            onTableRowClick: _ctx.handleTableRowClick,
            highlightSelection: true,
            firstColumnTabbable: true
          }, null, 8, ["table-config", "onTableRowClick"]),
          _createVNode(_component_egfr_form, {
            "form-id": "recipientEgfrForm",
            ref: "recipientEgfrForm",
            selection: _ctx.selection,
            canSave: _ctx.canSave,
            onSuccess: _ctx.handleSuccess
          }, null, 8, ["selection", "canSave", "onSuccess"])
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}