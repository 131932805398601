import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_egfr_sub_section = _resolveComponent("egfr-sub-section")!
  const _component_dialysis_sub_section = _resolveComponent("dialysis-sub-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "recipient-egfr-dialysis",
    lookupsToLoad: _ctx.lookupsToLoad,
    onLoaded: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loaded()))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('recipient_egfr_dialysis')), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_egfr_sub_section),
      _createVNode(_component_dialysis_sub_section)
    ]),
    _: 1
  }, 8, ["lookupsToLoad"]))
}