import { APIRecipientEgfrInterface } from '@/APIModels/recipients/types';
import { APINewResonse, APIPermittedActions, APIRules, APIShowResponse, RulesQuery, SaveResult } from '@/types';
import axios from 'axios';
import { APIRoute, EP } from '@/api-endpoints';
import { UIError } from '@/UIModels/error';
import { UISuccess } from '@/UIModels/success';
import { APIShowRecipientEgfrData, APISaveRecipientEgfrResponse } from '@/types';
import { useCurrentPageStore } from '@/stores/currentPage';
import vuexStore from '@/store'; // for gradual conversion, see fullUserDetails
import { UIRecipient } from '../recipient';

export class UIRecipientEgfr {
  public apiSource: APIRecipientEgfrInterface|null = null;
  public id: string|null = null;

  public date: string|null = null;
  public egfr: string|null = null;
  public comments: string|null = null;

  public loaded = false;

  public permittedActions: APIPermittedActions[] = [];

  get canEdit(): boolean {
    return this.permittedActions.includes(APIPermittedActions.Create) || this.permittedActions.includes(APIPermittedActions.Update);
  }

  // Define new UI view model structure
  public constructor(apiEgfr: APIRecipientEgfrInterface|null = null) {
    if (apiEgfr) this.updateFromAPIResponse(apiEgfr);
  }

  // Load resource data and permitted actions
  public async load(opts: { recipientId: string, id: string, query: RulesQuery }): Promise<void> {
    if (!opts.recipientId) return;
    if (opts.id) {
      await this.loadShow(opts);
    } else {
      await this.loadNew(opts);
    }
  }

  // Load list for specified recipient
  public static async loadFor(uiRecipient: UIRecipient): Promise<UIRecipientEgfr[]> {
    const recipientId = uiRecipient.clientId || '';
    let result: UIRecipientEgfr[] = [];
    const url = APIRoute(EP.recipients.egfr.index, [[':recipient_id', recipientId]]);
    await axios.get(url).then((response: any) => {
      const apiEgfr: APIRecipientEgfrInterface[] = response?.data?.egfrs || [];
      result = apiEgfr.map((item: APIRecipientEgfrInterface): UIRecipientEgfr => {
        return new UIRecipientEgfr(item);
      });
    }).catch((error: any) => {
      console.warn(error);
      throw new UIError('patient_egfr');
    });
    return result;
  }

  private async loadNew(opts: { recipientId: string, id: string, query: RulesQuery }): Promise<void> {
    const url = APIRoute(EP.recipients.egfr.index, [[':recipient_id', (opts.recipientId)], [':id', (opts.id)]]);
    try {
      const response: APINewResonse = await axios.get(url, { params: opts.query });
      this.permittedActions = response.data.permitted_actions;
      this.setRules(response.data.rules);
      this.loaded = true;
    } catch (error: unknown) {
      this.loaded = true;
      console.warn(error);
    }
  }

  private async loadShow(opts: { recipientId: string, id: string }): Promise<void> {
    const url = APIRoute(EP.recipients.egfr.show, [[':recipient_id', (opts.recipientId)], [':id', (opts.id)]]);
    try {
      const response: APIShowResponse<APIShowRecipientEgfrData> = await axios.get(url);
      const apiEgfr: APIRecipientEgfrInterface = response.data.egfr;
      this.permittedActions = response.data.permitted_actions;
      this.setRules(response.data.rules);
      this.updateFromAPIResponse(apiEgfr);
      this.loaded = true;
    } catch (error: unknown) {
      this.loaded = true;
      console.warn(error);
    }
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiEgfr: APIRecipientEgfrInterface) {
    this.apiSource = apiEgfr;
    this.id = apiEgfr._id?.$oid || null,
    this.date = apiEgfr.date || null;
    this.egfr = apiEgfr.egfr == null ? null : apiEgfr.egfr;
    this.comments = apiEgfr.comments || null;
  }

  private setRules(rules: APIRules): void {
    vuexStore.commit('validations/resetPrefix', 'patient_egfr');
    vuexStore.commit('validations/set', { rules: { ['patient_egfr']: rules } });
  }

  // Is this an unsaved New EGFR?
  get isNew(): boolean {
    return !this.id;
  }

  // Save UI EGFR edit state to the backend
  public save(opts: { selected: UIRecipientEgfr|null, recipient: UIRecipient }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const recipientId = opts.recipient.clientId;
      if (!recipientId) reject((new UIError('patient_egfr')));
      const id = opts.selected?.id;

      let method: any;
      let ep: string;
      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.egfr.update, [[':recipient_id', recipientId as string], [':id', id]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.egfr.create, [[':recipient_id', recipientId as string]]);
      }
      const payload = {
        egfr: this.extractPatch()
      };
      method(ep, payload).then((response: APISaveRecipientEgfrResponse) => {
        if (response.data.errors) {
          reject((new UIError('patient_egfr', response)).errorResult);
        } else {
          // Success! We may need to update the current page
          opts.recipient.load({ reload: true }).then(() => {
            resolve((new UISuccess(response)).getSaveResult());
          });
        }
      }).catch((errorResponse: any) => {
        reject((new UIError('patient_egfr', errorResponse)).errorResult);
      });
    });
  }

  // Generate request payload parameters to provide to API as part of Create or Update activity
  private extractPatch(): APIRecipientEgfrInterface {
    const result = {
      date: this.date || null,
      egfr: this.egfr || null,
      comments: this.comments || null
    };
    return result;
  }
}
