<template>
  <card-section
    section-id="recipient-egfr-dialysis"
    :lookupsToLoad="lookupsToLoad"
    @loaded="loaded()"
    >
    <template v-slot:header>
      {{ $t('recipient_egfr_dialysis') }}
    </template>
    <template v-slot:body>
      <egfr-sub-section/>
      <dialysis-sub-section/>
    </template>
  </card-section>
</template>

<script lang="ts">
import { Getter } from 'vuex-facing-decorator';
import { Component, mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import CardSection from '@/components/shared/CardSection.vue';
import { i18nMessages } from "@/i18n";
import EgfrSubSection from '@/components/recipients/egfrDialysis/EgfrSubSection.vue';
import DialysisSubSection from '@/components/recipients/egfrDialysis/DialysisSubSection.vue';

@Component({
  components: {
    CardSection,
    EgfrSubSection,
    DialysisSubSection
  },
  ...i18nMessages([
    require('@/components/recipients/_locales/common.json'),
    require('@/components/recipients/_locales/RecipientEgfrDialysis.json'),
  ]),
  emits: [
    'loaded',
  ],
})
export default class RecipientEgfr extends mixins(DateUtilsMixin) {
  // Lookup tables to be loaded by the CardSection component
  private isLoading = true;

  // Lookup tables to be loaded by the CardSection component
  private lookupsToLoad = ["dialysis_access_mode", "dialysis_laterality", 
    "dialysis_location_type", "dialysis_reason", "dialysis_type"];

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * @listens recipientEgfr#loaded
   * @emits loaded
   */
   public loaded(): void {
    this.isLoading = false;
    this.$emit('loaded', 'recipient_egfr');
  }
}
</script>
